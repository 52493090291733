<template>
	<div id="dialog">
		<div>
			<h3 v-if="params.title || params.warning">
				<span v-if="params.warning">{{ warning }}</span
				>{{ params.title }}
			</h3>
			<div v-if="params.html" v-html="params.message"></div>
			<template v-else>{{ params.message }}</template>

			<!-- Critical confirm -->
			<template v-if="params.actionKey">
				<p>
					Type <em>"{{ params.actionKey }}"</em> below to confirm.
				</p>
				<FormText
					ref="input"
					v-model="critical.ipValue"
					:autoFocus="true"
					:error="critical.error ? 'Required' : null"
				/>
				<div v-if="critical.error" class="error-msg small">Incorrect value</div>
			</template>
		</div>
		<div class="buttons-wrap">
			<FormButton
				ref="submit"
				:level="1"
				:wait="waitingSubmit"
				:value="params.okText"
				@click="doConfirm"
			/>
			<FormButton
				ref="cancel"
				:level="2"
				v-if="!params.hideCancel"
				:wait="waitingCancel"
				:value="params.cancelText"
				@click="doCancel"
			/>
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'
import FormText from '@/components/FormText'

export default {
	name: 'DialogConfirm',
	components: { FormButton, FormText },
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	data() {
		return {}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, [
			'params',
			'waitingSubmit',
			'doConfirm',
			'waitingCancel',
			'doCancel',
			'critical',
		]),
		warning() {
			return typeof this.params.warning == 'string'
				? this.params.warning + ' '
				: this.params.warning
				? 'Warning! '
				: ''
		},
	},
	watch: {
		// Critical confirm error.
		'critical.error'(newValue) {
			if (newValue) {
				this.error = 'Required'
				this.$refs.input.focus()
			}
		},
	},
}
</script>

<style scoped lang="scss">
#dialog .error-msg {
	margin-top: 0.05rem;
}
</style>
